import React, { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TLHClassesAPICalls from 'services/TLHClassesAPICalls';
import { Typography, Modal, Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';
import TLHEndpoints from 'services/TLHEndpoints';

// Modal Style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


export default function TLHLessonPlansPrintedInventory() {

  const [classesDataForCurrentMonth, setClassesDataForCurrentMonth] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ lessonNumber: '', studentsCount: 0, lessonId: -1 });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [teachersCopies, setTeachersCopies] = useState(0);
  const [studentsCopies, setStudentsCopies] = useState(0);

  var seenClasses = []

  // The enum order for yearGroup
    const YEAR_GROUP_ORDER = [
        "RECEPTION",
        "YEAR_1",
        "YEAR_2",
        "YEAR_3",
        "YEAR_4",
        "YEAR_5",
        "YEAR_6",
        "YEAR_7_AND_8",
        "YEAR_8_AND_9",
        "KS3_A",
        "KS3_B",
        "KS3_C",
        "GCSE_1",
        "GCSE_2",
        "AS_LEVEL",
        "A2_LEVEL"
    ];

  const getAllClassesRunningForASpecificMonthAndYear = (month, year) => {
    console.log(`Fetching classes for ${month}-${year}`);
    // You would return the actual data here in a real implementation
    TLHClassesAPICalls.getAllClassesRunningForASpecificMonthAndYear(month, year).then(result => {
      console.log(result.data)
      console.log(result.data.length)
      sortTLHClassesByYearGroupAndStartTime(result.data) 
      for (var i = 0; i < result.data.length; i = i + 1) {
        console.log(result.data[i])
      }
      setClassesDataForCurrentMonth(result.data)
      console.log("Got all data")
      console.log(classesDataForCurrentMonth)
    })
  };


// Function to sort the objects
function sortTLHClassesByYearGroupAndStartTime(data) {
    // Step 1: Sort the array based on yearGroup and classTime
    const sortedData = data.sort((a, b) => {
        // Compare yearGroup using the index in the YEAR_GROUP_ORDER
        const yearGroupComparison =
            YEAR_GROUP_ORDER.indexOf(a.yearGroup) - YEAR_GROUP_ORDER.indexOf(b.yearGroup);

        if (yearGroupComparison !== 0) {
            return yearGroupComparison; // Sort by yearGroup if different
        }

        // If yearGroup is the same, sort by classTime (earlier times first)
        return a.classTime.localeCompare(b.classTime);
    });

    // Step 2: Alternate background colors based on yearGroup
    let lastYearGroup = null;
    let colorToggle = false;

    const renderedData = sortedData.map(item => {
        // Check if the yearGroup has changed
        if (item.yearGroup !== lastYearGroup) {
            colorToggle = !colorToggle; // Alternate the color
            lastYearGroup = item.yearGroup; // Update the last yearGroup
        }

        // Assign a background color
        return {
            ...item,
            backgroundColor: colorToggle ? "#f0f8ff" : "#ffffff" // Light blue or white
        };
    });

    return renderedData;
}


    // Helper functions to change the month
  const handlePrevMonth = () => {
      setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
      setCurrentDate(addMonths(currentDate, 1));
  };

  // Get the month and year to display
  const monthYear = format(currentDate, 'MMMM-yyyy');
  const currentMonth = format(currentDate, 'MM'); // Format month as a number
  const currentYear = format(currentDate, 'yyyy'); // Format year as a number


  // Generate the days for the current month
  const daysInMonth = eachDayOfInterval({
      start: startOfMonth(currentDate),
      end: endOfMonth(currentDate),
  });

   // Fetch classes when month/year changes
   useEffect(() => {
        // Call the function to fetch classes for the given month and year
        getAllClassesRunningForASpecificMonthAndYear(currentMonth, currentYear);
    }, [currentDate]); // Trigger this whenever the currentDate changes

        // Handle opening the modal for lesson details
    const handleOpenModal = (lessonNumber, studentsCount, lessonId) => {
        setModalContent({ lessonNumber, studentsCount, lessonId });
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };


  const handlePrintRequest = () => {

    console.log("Teacher copies being requested: " + teachersCopies)
    console.log("Student copies being requested: " + studentsCopies)
    
    console.log("Modal content: " + modalContent)

    TLHEndpoints.printLessonPlans(modalContent, teachersCopies, studentsCopies).then(result => {
      console.log(result.data)
      setModalOpen(false);
      setTeachersCopies(0)
      setStudentsCopies(0)
    })

    // Close the modal after submitting
    setModalOpen(false);
    setTeachersCopies(0)
    setStudentsCopies(0)
};

  // Function to handle merging duplicate classes into one row
  const mergeClassWithDuplicates = (classData) => {
    let mergedLessons = { ...classData.lessonNumbersForEachDate };

    classData.duplicateClassIds.forEach((duplicateId) => {
        const duplicateClass = classesDataForCurrentMonth.find((c) => c.classId === duplicateId);
        if (duplicateClass) {
            for (const [date, lessonInfo] of Object.entries(duplicateClass.lessonNumbersForEachDate)) {
                if (mergedLessons[date]) {
                    mergedLessons[date] = [
                        ...mergedLessons[date],
                        ...lessonInfo,
                    ].sort((a, b) => a - b); // Sort the lesson numbers
                } else {
                    mergedLessons[date] = lessonInfo;
                }
            }
        }
    });

    return mergedLessons;
};

   // Function to check if the class is a duplicate (so we don't render it multiple times)
const isClassSeenDuplicate = (classId) => {
  // console.log("Checking: " + classId)
  // console.log(seenClasses)
    for (var i = 0; i < seenClasses.length; i = i + 1) {
      if (classId == seenClasses[i]) {
        console.log("Returning true")
        return true
      }
    }
    seenClasses.push(classId)
    for (var i = 0; i < classesDataForCurrentMonth.length; i = i + 1) {
      // console.log("checking now")
      // console.log(classesDataForCurrentMonth[i].classId == classId)
      if (classesDataForCurrentMonth[i].classId == classId) {
        console.log("Handling add of duplicate classes")
        var currClass = classesDataForCurrentMonth[i]
        for (var j = 0; j < currClass.duplicateClassIds; j = j + 1) {
          seenClasses.push(currClass.duplicateClassIds[j])
        }
      }
    }
    // console.log("Returning false")
    return false
    // return classesDataForCurrentMonth.some((classData) => classData.duplicateClassIds.includes(classId));
};

  return (
    
    <div>
     <Paper>
        <Grid container justifyContent="space-between" alignItems="center" padding={2}>
            <Button onClick={handlePrevMonth} startIcon={<ArrowBackIosIcon />}>
                Previous
            </Button>
            <Typography variant="h6">{monthYear}</Typography>
            <Button onClick={handleNextMonth} endIcon={<ArrowForwardIosIcon />}>
                Next
            </Button>
        </Grid>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Day</TableCell>
                        {/* Dynamically create headers for the month */}
                        {daysInMonth.map((day) => (
                            <TableCell key={day}>
                                {format(day, 'd')}<br />{format(day, 'EEE')}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                        {classesDataForCurrentMonth
                            .filter((classData) => !isClassSeenDuplicate(classData.classId)) // Filter out duplicate classes
                            .map((classData, index) => {
                                const mergedLessons = mergeClassWithDuplicates(classData);
                                // Get background color for the current row

                                return (
                                    <React.Fragment key={classData.classId}>
                                        {/* Display a gap between year groups */}
                                        {index === 0 || classesDataForCurrentMonth[index - 1].yearGroup !== classData.yearGroup ? (
                                            <TableRow style={{ height: '20px' }}>
                                                <TableCell colSpan={daysInMonth.length + 1}></TableCell>
                                            </TableRow>
                                        ) : null}
                                        <TableRow style={{ backgroundColor: '#e8f4fa' }}>
                                            {/* Show class name in the first column */}
                                            <TableCell>{classData.className}</TableCell>
                                            {daysInMonth.map((day) => {
                                                const formattedDate = format(day, 'yyyy-MM-dd');
                                                const lessonData = mergedLessons[formattedDate];

                                                const lessonNumberText = lessonData
                                                    ? lessonData[0]
                                                    : '';

                                                return (
                                                    <TableCell key={formattedDate}>
                                                        {lessonNumberText && (
                                                            <Button
                                                                onClick={() =>
                                                                    handleOpenModal(
                                                                        lessonData ? lessonData[0] : '',
                                                                        lessonData ? lessonData[1] : '',
                                                                        lessonData ? lessonData[2] : '',
                                                                    )
                                                                }
                                                            >
                                                                {lessonNumberText}
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                    </TableBody>

                {/* </TableBody> */}
            </Table>
        </TableContainer>
         {/* Modal for lesson details */}
         <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2">
                        Print Request
                    </Typography>
                    <Typography sx={{ mt: 2 }}>Lesson Number: {modalContent.lessonNumber}</Typography>
                    <Typography sx={{ mt: 2 }}>Students Count: {modalContent.studentsCount}</Typography>

                    {/* Input fields for Teacher's and Student's copies */}
                    <TextField
                        label="Teacher's Copies"
                        type="number"
                        fullWidth
                        value={teachersCopies}
                        onChange={(e) => setTeachersCopies(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        label="Student's Copies"
                        type="number"
                        fullWidth
                        value={studentsCopies}
                        onChange={(e) => setStudentsCopies(e.target.value)}
                        margin="normal"
                    />

                    {/* Submit Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePrintRequest}
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Print Now
                    </Button>
                </Box>
            </Modal>
    </Paper>
      
    </div>
  );
}

